import React from "react";
import { Box, Typography } from "@mui/material";
import RestaurantBackground from "../components/assets/restaurant.jpg";
import CheckIcon from "../components/assets/check-icon.jpg";
import ToolCards from "../components/ToolCards";

export default function RestaurantesPage() {
  return (
    <Box
      sx={{
        width: "100%",
        minHeight: "100vh",
        backgroundImage: `url(${RestaurantBackground})`,
        backgroundPosition: "center 35%",
        backgroundSize: "cover",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        padding: "20px",
      }}
    >
      <Box
        sx={{
          textAlign: "center",
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          padding: "20px",
          maxWidth: "85%",
          marginTop: "20px",
        }}
      >
        <Typography
          variant="h2"
          sx={{
            fontFamily: "Montserrat",
            marginBottom: "20px",
            marginTop: "20px",
            color: "#000",
            fontSize: { xs: "28px", sm: "32px", md: "46px" },
          }}
        >
          Restaurantes
        </Typography>
        <Typography
          variant="h6"
          style={{
            fontFamily: "Montserrat",
            lineHeight: 2,
            color: "#000",
          }}
          sx={{
            textAlign: "justify",
            fontSize: { xs: "14px", sm: "16px", md: "18px" },
          }}
        >
          En este tipo de negocios, PVG permite el control integral de los
          mismos, permitiendo a los garzones ingresar pedidos, modificarlos,
          generar preboletas, cambiar mesas, juntarlas, etc. En el módulo de
          Caja, se pagan las cuentas y se emitirán las boletas electrónicas o
          vales internos según corresponda. Se pueden usar todos los Centros de
          Producción que se necesiten, en los cuales se imprimirán las comandas
          que vayan llegando. El módulo de Backoffice, permite emitir Informes y
          Consultas específicas para la gestión de Restaurantes, como también el
          control de la receta de los productos que la usen.
        </Typography>
      </Box>

      {/* ToolCards debajo del texto */}
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: "center",
          marginTop: "30px",
          gap: "20px",
        }}
      >
        <Box>
          <ToolCards
            imageSource={CheckIcon}
            Title="Control de stocks productos terminados y Materias Primas."
            Width={258}
            Height={258}
          />
        </Box>
        <Box>
          <ToolCards
            imageSource={CheckIcon}
            Title="Múltiples Centros de Producción."
            Width={258}
            Height={258}
          />
        </Box>
        <Box>
          <ToolCards
            imageSource={CheckIcon}
            Title="Gestión orientada al Producto, Garzón, Cajero y Mesas."
            Width={258}
            Height={258}
          />
        </Box>
      </Box>
    </Box>
  );
}
