import { Box, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import ToolCards from "../components/ToolCards";
import TransparencyImage from "../components/assets/transparencia.png";
import AntiVirusImage from "../components/assets/anti-virus.png";
import OnepcImage from "../components/assets/one-pc.png";
import WindowsLogo from "../components/assets/windows-logo.jpg";
import NoWifyImage from "../components/assets/no-wifi.jpg";
import BarLectorImage from "../components/assets/bar-lector.jpg";
import ProximityCard from "../components/assets/proximity-card.jpg";
import StorageLogo from "../components/assets/storage-logo.jpg";
import EasySoftwareImage from "../components/assets/easy-software.jpg";
import PdfExcelHtmlImage from "../components/assets/pdf-excel-html.jpg";
import ServerBasedImage from "../components/assets/server-based-network.jpg";
import TouchScreenImage from "../components/assets/touch-screen.jpg";

export default function HomePage() {
  return (
    <div>
      <Box
        style={{
          marginTop: "25px",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          maxWidth: "80%",
          marginLeft: "10%",
        }}
      >
        <Typography
          variant="h2"
          sx={{ fontFamily: "Montserrat", marginBottom: "20px" }}
        >
          ¿Quiénes somos?
        </Typography>
        <Box style={{ marginTop: "20px" }}>
          <Typography
            style={{
              fontFamily: "Montserrat",
              marginBottom: "35px",
              textAlign: "justify",
            }}
            sx={{ lineHeight: 2 }}
          >
            Somos una empresa dedicada a la Venta y Desarrollo de Sistemas
            Computacionales, basados en Arquitectura de alta tecnología y
            flexibilidad, pudiendo implementar sistemas adecuados a un pequeño
            local, como a una gran cadena. Poseemos el conocimiento necesario de
            la industria, como para ser la mejor alternativa al momento de
            elegir un software para el control integral de su negocio. Con la
            implementación de nuestros Sistemas, Usted podrá ver a corto plazo
            una mayor efectividad en los procesos administrativos de su empresa,
            lo cual finalmente, se traducirá en reducción de costos y tiempo
            para dedicarse a su rubro. El nombre de nuestro software es PVG
            (Punto de Venta y Gestión).
          </Typography>
          <Divider>
            {" "}
            <Chip
              label="CARACTERÍSTICAS PVG"
              size="large"
              style={{ fontFamily: "Montserrat", fontWidth: "bold" }}
            />{" "}
          </Divider>
        </Box>
      </Box>
      <Box
        style={{
          marginTop: "25px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexWrap: "wrap",
          maxWidth: "80%",
          marginLeft: "12%",
        }}
      >
        <Box style={{ flexBasis: "25%", padding: "10px" }}>
          <ToolCards
            imageSource={TransparencyImage}
            Title="Carga inicial de base de datos"
            Description="La carga inicial de PVG, se realiza por medio de una planilla Excel, la cual será entregada al
        cliente por JASoftware para comenzar, de tal forma que el cliente solo debe llenar las
        columnas requeridas, para efectuar la carga completa de los productos, precios, costos y
        familias o clasificaciones."
            Height={parseInt("575")}
            Width={parseInt("300")}
          />
        </Box>
        <Box style={{ flexBasis: "25%", padding: "10px" }}>
          <ToolCards
            imageSource={WindowsLogo}
            Title="Windows"
            Description="Se instala en cualquier PC con Windows."
            Height={parseInt("575")}
            Width={parseInt("300")}
          />
        </Box>
        <Box style={{ flexBasis: "25%", padding: "10px" }}>
          <ToolCards
            imageSource={NoWifyImage}
            Title="Sin dependencia a internet"
            Description="PVG no depende de una conexión a Internet para funcionar, pero se necesita para la emisión de Boletas o Facturas electrónicas, Soporte Remoto por parte de JASoftware, o para la conexión de un usuario autorizado para ingresar
            remotamente al sistema."
            Height={parseInt("575")}
            Width={parseInt("300")}
          />
        </Box>
        <Box style={{ flexBasis: "25%", padding: "10px" }}>
          <ToolCards
            imageSource={AntiVirusImage}
            Title="Ambiente Protegido"
            Description="PVG crea un ambiente
            protegido, de tal manera que no le afectan los virus. El pc podría estar infectado, pero los
            datos de PVG por estar en una burbuja no se verán afectados, se pueden copiar a otro equipo y continuar trabajando."
            Height={parseInt("575")}
            Width={parseInt("300")}
          />
        </Box>
        <Box style={{ flexBasis: "25%", padding: "10px", paddingTop: "30px" }}>
          <ToolCards
            imageSource={BarLectorImage}
            Title="Lectores de Códigos de Barra"
            Description="PVG, puede incluir el uso de lectores de códigos de barra (escaner), para el ingreso de
            productos tanto en el módulo de ventas, precios, compras, stocks e inventario."
            Height={parseInt("575")}
            Width={parseInt("300")}
          />
        </Box>
        <Box style={{ flexBasis: "25%", padding: "10px", paddingTop: "30px" }}>
          <ToolCards
            imageSource={ProximityCard}
            Title="Tarjetas de proximidad"
            Description="PVG, puede leer, si se instala el lector correspondiente en cada pc, tarjetas de proximidad, de
            tal forma de no tener que digitar códigos de usuario o claves para el ingreso o efectuar
            autorizaciones."
            Height={parseInt("575")}
            Width={parseInt("300")}
          />
        </Box>
        <Box style={{ flexBasis: "25%", padding: "10px", paddingTop: "30px" }}>
          <ToolCards
            imageSource={StorageLogo}
            Title="Requiere poco almacenamiento"
            Description="PVG, como está diseñado para generar la interfaz gráfica en el momento de la interacción con
            el usuario, no necesita utilizar espacio en disco para el almacenamiento de la
            información gráfica del Software."
            Height={parseInt("575")}
            Width={parseInt("300")}
          />
        </Box>
        <Box style={{ flexBasis: "25%", padding: "10px", paddingTop: "30px" }}>
          <ToolCards
            imageSource={EasySoftwareImage}
            Title="Fácil de usar"
            Description="Los usuarios de PVG, no necesitan saber de informática, pues por ser un sistema amigable,
            solo requiere respuestas y confirmaciones en el idioma español."
            Height={parseInt("575")}
            Width={parseInt("300")}
          />
        </Box>
        <Box style={{ flexBasis: "25%", padding: "10px", paddingTop: "30px" }}>
          <ToolCards
            imageSource={TouchScreenImage}
            Title="Touchscreen"
            Description="PVG, está diseñado idealmente para ser usado con monitores touchscreen, pero puede ser
            usado eficientemente también con monitores normales."
            Height={parseInt("575")}
            Width={parseInt("300")}
          />
        </Box>
        <Box style={{ flexBasis: "25%", padding: "10px", paddingTop: "30px" }}>
          <ToolCards
            imageSource={PdfExcelHtmlImage}
            Title="Generar informes"
            Description="PVG genera los informes en Excel, HTML (PDF), o en archivos de texto (planos), lo cual permite
            la comunicación con cualquier otro software, ya sea de entrada o salida.."
            Height={parseInt("575")}
            Width={parseInt("300")}
          />
        </Box>
        <Box style={{ flexBasis: "25%", padding: "10px", paddingTop: "30px" }}>
          <ToolCards
            imageSource={OnepcImage}
            Title="Único PC"
            Description="En caso de usar solo un PC, este puede tener ventas, cajas y backoffice. En resumen, se puede utilizar todo el sistema en un solo equipo si es necesario."
            Height={parseInt("575")}
            Width={parseInt("300")}
          />
        </Box>
        <Box style={{ flexBasis: "25%", padding: "10px", paddingTop: "30px" }}>
          <ToolCards
            imageSource={ServerBasedImage}
            Title="Red de pc's"
            Description="En caso de tener una red de PC's, se puede usar uno de ellos como Servidor y tener por separado,
            PC's de caja, ventas y backoffice. Se puede armar una red como se necesite, dependiendo de la
            configuración del local."
            Height={parseInt("575")}
            Width={parseInt("300")}
          />
        </Box>
      </Box>
    </div>
  );
}
