import React, { useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { styled } from "@mui/system";

const navItems = [
  "Inicio",
  "Almacenes",
  "Farmacias",
  "Restaurantes",
  "Repuestos automotrices",
];

const StyledNavLink = styled(NavLink)(({ theme }) => ({
  color: "#fff",
  "&.active": {
    color: "#FFCA28",
  },
}));

export default function DrawerAppBar() {
  const [openDrawer, setOpenDrawer] = useState(false);
  const location = useLocation();

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  const formatNavItem = (item) => {
    if (item === "Repuestos automotrices") {
      return "repuestos";
    }
    return item.toLowerCase();
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        component="nav"
        position="fixed"
        style={{ backgroundColor: "#333" }}
      >
        <Toolbar>
          <Typography
            variant="h4"
            component="div"
            sx={{
              color: "#1E90FF",
              whiteSpace: "nowrap",
              fontFamily: "merriweather",
              fontStyle: "italic",
              letterSpacing: ".2rem",
            }}
          >
            JASoftware
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: "block", md: "none" } }}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer}
            >
              <MenuIcon />
            </IconButton>
          </Box>
          <Box sx={{ display: { xs: "none", md: "block" } }}>
            {navItems.map((item, index) => (
              <Button
                key={item}
                sx={{ color: "#fff", fontFamily: "Montserrat" }}
                component={StyledNavLink}
                to={index === 0 ? "/" : `/${formatNavItem(item)}`}
              >
                {item.toUpperCase()}
              </Button>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer anchor="left" open={openDrawer} onClose={toggleDrawer}>
        <List>
          {navItems.map((item, index) => (
            <React.Fragment key={item}>
              <ListItem
                onClick={toggleDrawer}
                component={Link}
                to={index === 0 ? "/" : `/${formatNavItem(item)}`}
                sx={{
                  color:
                    location.pathname === (index === 0 ? "/" : `/${formatNavItem(item)}`) ? "blue" : "inherit",
                }}
              >
                {item.toUpperCase()}
              </ListItem>
            </React.Fragment>
          ))}
        </List>
      </Drawer>
      <Toolbar />
    </Box>
  );
}
