import { Box, Typography } from "@mui/material";
import JASoftwareLogo from "../components/assets/jas-software-logo.png";
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";

export default function BottomBar() {
  return (
    <div>
      <Box
        component="div"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          bgcolor: "#F5F5F5",
        }}
      >
        <img
          src={JASoftwareLogo}
          alt="JASoftware Logo"
          style={{
            height: 100,
            width: 100,
            maxHeight: { xs: 233, md: 167 },
            maxWidth: { xs: 500, md: 300 },
          }}
        />
        <Box style={{display:"flex"}}>
        <EmailIcon style={{fontSize:"20px", marginRight:"5px"}}/>
        <Typography style={{ fontFamily: "Montserrat" }}>
          contacto@jasoftware.cl
        </Typography>
        </Box>
        <Box style={{display:"flex"}}>
        <LocalPhoneIcon style={{fontSize:"20px", marginRight:"5px"}}/>
        <Typography style={{ fontFamily: "Montserrat" }}>
          +562 244 2533
        </Typography>
        </Box>
        <Typography
          style={{
            fontFamily: "Montserrat",
            marginTop: "10px",
          }}
        >
          JASoftware
        </Typography>
      </Box>
    </div>
  );
}
