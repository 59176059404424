import React from "react";
import { Box, Typography } from "@mui/material";
import RepuestosBackground from "../components/assets/repuestos.jpg";
import CheckIcon from "../components/assets/check-icon.jpg";
import ToolCards from "../components/ToolCards";

export default function RestaurantesPage() {
  return (
    <Box
      sx={{
        width: "100%",
        minHeight: "100vh",
        backgroundImage: `url(${RepuestosBackground})`,
        backgroundPosition: "center 35%",
        backgroundSize: "cover",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        padding: "20px",
      }}
    >
      <Box
        sx={{
          textAlign: "center",
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          padding: "20px",
          maxWidth: "85%",
          marginTop: "20px",
        }}
      >
        <Typography
          variant="h2"
          sx={{
            fontFamily: "Montserrat",
            marginBottom: "20px",
            marginTop: "20px",
            color: "#000",
            fontSize: { xs: "28px", sm: "32px", md: "46px" },
          }}
        >
          Repuestos Automotrices
        </Typography>
        <Typography
          variant="h6"
          style={{
            fontFamily: "Montserrat",
            lineHeight: 2,
            color: "#000",
          }}
          sx={{
            textAlign: "justify",
            fontSize: { xs: "14px", sm: "16px", md: "18px" },
          }}
        >
          El software PVG para controlar negocios de este tipo, permite el
          control detallado de códigos del proveedor, números de parte y de
          serie, como también el control detallado de la ubicación en bodega del
          producto. Al vender se pueden autorizar descuentos a productos o
          definir características especiales de precios para una Familia de
          Productos. En el módulo de Backoffice, destaca el ingreso de las
          facturas de Proveedores totalizadas para cuadrar la correcta
          digitación de las mismas. Todos los informes y consultas, permiten
          hacer comparaciones de márgenes y costos, precios y descuentos, de tal
          forma de hacer una administración eficiente invirtiendo en ello el
          mínimo esfuerzo.
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: "center",
          marginTop: "30px",
          gap: "20px",
        }}
      >
        <Box>
          <ToolCards
            imageSource={CheckIcon}
            Title="Control y cuadratura de dineros"
            Width={258}
            Height={258}
          />
        </Box>
        <Box>
          <ToolCards
            imageSource={CheckIcon}
            Title="Ubiación de los Productos en bodega con dirección exacta."
            Width={258}
            Height={258}
          />
        </Box>
      </Box>
    </Box>
  );
}
