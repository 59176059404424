import { HashRouter, Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import AlmacenesPage from "./pages/AlmacenesPage";
import FarmaciasPage from "./pages/FarmaciasPage";
import RepuestosPage from "./pages/RepuestosPage";
import RestaurantPage from "./pages/RestaurantPage";
import NavigationBar from "./components/Navbar";
import BottomBar from "./components/BottomBar";


function App() {
  return (
    <div className="App">
      <HashRouter>
      <NavigationBar/>
      <Routes>
        <Route exact path="/" element={<HomePage />} />
        <Route exact path="/almacenes" element={<AlmacenesPage />} />
        <Route exact path="/farmacias" element={<FarmaciasPage />} />
        <Route exact path="/restaurantes" element={<RestaurantPage />} />
        <Route exact path="/repuestos" element={<RepuestosPage />} />
      </Routes>  
      <BottomBar/>
      </HashRouter>
    </div>
  );
}

export default App;
