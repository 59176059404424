import React from "react";
import { Box, Typography } from "@mui/material";
import AlmacenBackground from "../components/assets/almacen.jpg";
import CheckIcon from "../components/assets/check-icon.jpg";
import ToolCards from "../components/ToolCards";

export default function FarmaciasPage() {
  return (
    <Box
      sx={{
        width: "100%",
        minHeight: "100vh", 
        backgroundImage: `url(${AlmacenBackground})`,
        backgroundSize: "cover",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        overflow: "auto", 
      }}
    >
      <Box
        sx={{
          textAlign: "center",
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          maxWidth: "85%",
          padding: "20px",
          marginTop: "20px",
        }}
      >
        <Typography
          variant="h2"
          sx={{
            fontFamily: "Montserrat",
            marginBottom: "20px",
            marginTop: "20px",
            color: "#000",
            fontSize: { xs: "28px", sm: "32px", md: "46px" },
          }}
        >
          Almacenes
        </Typography>
        <Typography
          variant="h6"
          style={{
            fontFamily: "Montserrat",
            lineHeight: 2,
            color: "#000",
          }}
          sx={{
            textAlign: "justify",
            fontSize: { xs: "14px", sm: "16px", md: "18px" },
          }}
        >
          La versión de PVG para Almacenes, permite el control y cuadratura de
          los dineros, como los stocks, permitiendo usar en las bodegas el
          cálculo del Precio Promedio Ponderado, o en su defecto el Precio
          Última Compra. El módulo de Inventarios, permite efectuar el proceso
          el día 1, si no se terminó este proceso en el mismo día, se puede
          comenzar a vender sin problemas en los días posteriores sin afectar a
          las cuadraturas, ni al cálculo de los procesos de Inventario, ya que
          al finalizar, la actualización se hace por diferencia y no por valor
          absoluto de stock.
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: "center",
          marginTop: "20px",
          gap: "20px",
        }}
      >
        <Box style={{ flexBasis: "100%" }}>
          <ToolCards
            imageSource={CheckIcon}
            Title="Control y cuadratura de dineros"
            Width={parseInt("258")}
            Height={parseInt("258")}
          />
        </Box>
        <Box style={{ flexBasis: "100%", marginBottom:"20px" }}>
          <ToolCards
            imageSource={CheckIcon}
            Title="Actualización por diferencia"
            Width={parseInt("258")}
            Height={parseInt("258")}
          />
        </Box>
      </Box>
    </Box>
  );
}
