import * as React from "react";
import { Box, Typography } from "@mui/material";
import AspectRatio from "@mui/joy/AspectRatio";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import CardOverflow from "@mui/joy/CardOverflow";


export default function ToolCards({ imageSource, Title, Description, Height, Width }) {
  return (
    <Card variant="outlined" sx={{ width: Width, height: Height, '&:hover': { boxShadow: '0px 0px 10px 5px rgba(0,0,0,0.2)',  transition: 'box-shadow 0.3s ease-in-out' } }}>
      <CardOverflow>
        <AspectRatio ratio="2">
          <Box
            component="img"
            sx={{
              height: 233,
              width: 400,
              maxHeight: { xs: 233, md: 167 },
              maxWidth: { xs: 500, md: 300 },
            }}
            src={imageSource}
          />
        </AspectRatio>
      </CardOverflow>
      <CardContent>
        <Typography style={{ fontWeight: "bold" }} level="title-md">{Title} </Typography>
        <Typography style={{ fontFamily: "Montserrat", textAlign:"justify" }} sx={{ lineHeight: 2 }} level="body-sm">{Description} </Typography>
      </CardContent>
    </Card>
  );
}
