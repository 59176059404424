import React from "react";
import { Box, Typography } from "@mui/material";
import PharmacyBackground from "../components/assets/pharmacy.jpg";
import CheckIcon from "../components/assets/check-icon.jpg";
import ToolCards from "../components/ToolCards";

export default function FarmaciasPage() {
  return (
    <Box
      sx={{
        width: "100%",
        minHeight: "100vh",
        backgroundImage: `url(${PharmacyBackground})`,
        backgroundSize: "cover",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        padding: "20px", // Agrega espacio entre el contenido y el borde de la página
      }}
    >
      {/* Contenedor blanco */}
      <Box
        sx={{
          textAlign: "center",
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          padding: "20px",
          maxWidth: "85%",
          marginBottom: "20px", // Agrega espacio entre el contenedor blanco y los ToolCards
        }}
      >
        {/* Contenido dentro del contenedor blanco */}
        <Typography
          variant="h2"
          sx={{
            fontFamily: "Montserrat",
            marginBottom: "20px",
            marginTop: "20px",
            color: "#000",
            fontSize: { xs: "28px", sm: "32px", md: "46px" },
          }}
        >
          Farmacias
        </Typography>
        <Typography
          variant="h6"
          style={{
            fontFamily: "Montserrat",
            lineHeight: 2,
            color: "#000",
          }}
          sx={{
            textAlign: "justify",
            fontSize: { xs: "14px", sm: "16px", md: "18px" },
          }}
        >
          La versión para Farmacias del software PVG, permite controlar las
          ventas y administración de este tipo de negocios. Por ser un software
          especializado en Farmacias, el Maestro de Productos, como todos los
          programas de Consultas e Informes del sistema, están orientados a los
          medicamentos, permitiendo administrar la relación con laboratorios o
          proveedores de medicamentos e insumos.
        </Typography>
      </Box>

      {/* Contenedor para ToolCards fuera del contenedor blanco */}
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: { xs: "repeat(1, 1fr)", sm: "repeat(2, 1fr)", md: "repeat(4, 1fr)" },
          gap: "20px",
        }}
      >
        {/* ToolCards */}
        <ToolCards
          imageSource={CheckIcon}
          Title="Búsqueda de productos por código de barra, código interno, nombre o principio activo"
          Width={parseInt("258")}
          Height={parseInt("258")}
        />
        <ToolCards
          imageSource={CheckIcon}
          Title="Control de los pagos con distintas formas de pago, únicas o mezcladas."
          Width={parseInt("258")}
          Height={parseInt("258")}
        />
        <ToolCards
          imageSource={CheckIcon}
          Title="Control de Abonos de Clientes."
          Width={parseInt("258")}
          Height={parseInt("258")}
        />
        <ToolCards
          imageSource={CheckIcon}
          Title="Control de Créditos a Clientes y Personal."
          Width={parseInt("258")}
          Height={parseInt("258")}
        />
      </Box>
    </Box>
  );
}
